import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const products = [
  {
    name: 'Weekly',
    desc: 'Spare change per week charged'
  },
  {
    name: 'Monthly',
    desc: 'Spare change per month charged'
  }
];

interface InfoProps {
}

export default function Info({ }: InfoProps) {
  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom>
        Billing Periods
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText
              sx={{ mr: 2 }}
              primary={product.name}
              secondary={product.desc}
            />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}
