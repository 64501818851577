import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Menu, MenuItem, PaletteMode, StepButton } from '@mui/material';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import AddressForm, { CreditCard, User } from './AddressForm';
import getCheckoutTheme from './getCheckoutTheme';
import Info from './Info';
import InfoMobile from './InfoMobile';
import PaymentForm from './PaymentForm';
import Review from './Review';
import ToggleColorMode from './ToggleColorMode';
import EventEmitter from 'events';
import axios from 'axios';
import { AuthContext } from './context/AuthContext';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { PlaidLink } from 'react-plaid-link';
import PlaidLinkClass from './PlaidLinkClass';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

const steps = ['Account details', 'Payment account', 'Spare change account'];

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',

};

export default function Account() {
  const { user, register } = React.useContext(AuthContext);
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const defaultTheme = createTheme({ palette: { mode: 'light' } });
  const [activeStep, setActiveStep] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [recurringIsActive, setRecurringIsActive] = React.useState(user.recurringIsActive);
  const [spareIsActive, setSpareIsActive] = React.useState(user.isActive);
  const [isActive, setIsActive] = React.useState(user.isActive || user.recurringIsActive);

  const stripe = useStripe();
  const elements = useElements();
  const addressFormRef = React.useRef<{ getFormData: () => User; }>(null);

  React.useEffect(() => {
    if(searchParams.get("setup_intent")) {
      setActiveStep(2);
    }
  }, []);

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const updateAllGivingStatus = async () => {
    
  }

  const pauseSpareGiving = async () => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/pauseGiving' : 'https://realchange.club:8080/api/pauseGiving', { }, { withCredentials: true });
    setSpareIsActive(false);
  };

  const resumeSpareGiving = async () => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/resumeGiving' : 'https://realchange.club:8080/api/resumeGiving', { }, { withCredentials: true });
    setSpareIsActive(true);
  }

  const pauseRecurringGiving = async () => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/pauseRGiving' : 'https://realchange.club:8080/api/pauseRGiving', { }, { withCredentials: true });
    setRecurringIsActive(false);
  };

  const resumeRecurringGiving = async () => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/resumeRGiving' : 'https://realchange.club:8080/api/resumeRGiving', { }, { withCredentials: true });
    setRecurringIsActive(true);
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNext = async () => {
    if(activeStep == 0) {
      const formData = addressFormRef.current?.getFormData();
      if(!formData?.email || !formData?.password) {
        alert("Please fill out the necessary data");
        return;
      }
      
      await register(formData?.email, formData?.password, true);
      window.location.reload();
    } else if(activeStep == 1 && elements !== null && stripe !== null) {
      const {error: submitError} = await elements.submit();
      if(submitError) return;

      const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/intent' : 'https://realchange.club:8080/api/intent', { }, { withCredentials: true });
      const clientSecret = res.data.clientSecret;
      
      const {error} = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://realchange.club/account',
        },
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Grid container sx={{ height: { xs: '100%', sm: '100dvh' } }}>
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
            width: '100%',
            backgroundColor: { xs: 'transparent', sm: 'background.default' },
            alignItems: 'start',
            pt: { xs: 2, sm: 4 },
            px: { xs: 2, sm: 10 },
            gap: { xs: 4, md: 8 },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: { sm: 'space-between', md: 'flex-end' },
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexGrow: 1,
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <Stepper
              nonLinear 
                id="desktop-stepper"
                activeStep={activeStep}
                sx={{
                  width: '100%',
                  height: 40,
                }}
              >
                {steps.map((label, index) => (
                  <Step
                    sx={{
                      ':first-child': { pl: 0 },
                      ':last-child': { pr: 0 },
                    }}
                    key={label}
                  >
                                      <StepButton color="inherit" onClick={handleStep(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '80%',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxHeight: '720px',
              gap: { xs: 5, md: 'none' },
            }}
          >
            <Stepper
              id="mobile-stepper"
              nonLinear 
              activeStep={activeStep}
              alternativeLabel
              sx={{ display: { sm: 'flex', md: 'none' } }}
            >
              {steps.map((label, index) => (
                <Step
                  sx={{
                    ':first-child': { pl: 0 },
                    ':last-child': { pr: 0 },
                    '& .MuiStepConnector-root': { top: { xs: 6, sm: 12 } },
                  }}
                  key={label}
                >
                  <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <Stack spacing={2} useFlexGap>
                <Typography variant="h1">📦</Typography>
                <Typography variant="h5">Thank you for your order!</Typography>
                <Typography variant="body1" color="text.secondary">
                  Your order number is
                  <strong>&nbsp;#140396</strong>. We have emailed your order
                  confirmation and will update you once its shipped.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    alignSelf: 'start',
                    width: { xs: '100%', sm: 'auto' },
                  }}
                >
                  Go to my orders
                </Button>
              </Stack>
            ) : (
              <React.Fragment>
                { activeStep === 0 && <AddressForm ref={addressFormRef} user={user} /> }
                { activeStep === 1 && <PaymentElement /> }
                { activeStep === 2 && <Box textAlign={"center"}><PlaidLinkClass></PlaidLinkClass></Box> }

                {activeStep !== 2 && 
                <Box 
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    flexGrow: 1,
                    gap: 1,
                    pb: { xs: 12, sm: 0 },
                    mt: { xs: 2, sm: 0 },
                    mb: '60px',
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<ChevronRightRoundedIcon />}
                    onClick={handleNext}
                    sx={{
                      width: { xs: '100%', sm: 'fit-content' },
                    }}
                  >
                    Update
                  </Button>

                  {<PopupState variant="popover" popupId="demo-popup-menu">
  {(popupState) => (
    <React.Fragment>
      <Button variant="contained" color="info" {...bindTrigger(popupState)}>
        Update Giving
      </Button>
      <Menu {...bindMenu(popupState)}>
        { !spareIsActive && <MenuItem onClick={async (e) => {
          await resumeSpareGiving();
          popupState.close();
        }}>Resume Spare Giving</MenuItem> } 
        { spareIsActive && <MenuItem onClick={async (e) => {
          await pauseSpareGiving();
          popupState.close();
        }}>Pause Spare Giving</MenuItem> }


        { !recurringIsActive && <MenuItem onClick={async (e) => {
          await resumeRecurringGiving();
          popupState.close();
        }}>Resume Recurring Giving</MenuItem> } 
        { recurringIsActive && <MenuItem onClick={async (e) => {
          await pauseRecurringGiving();
          popupState.close();
        }}>Pause Recurring Giving</MenuItem> }
      </Menu>
    </React.Fragment>
  )}
</PopupState>
                  }
                  
                </Box> }
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
