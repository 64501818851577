// src/pages/AboutUs.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const AboutUs = () => {
  return (
    <Container maxWidth="md">
      <Box mt={5}>
  <Typography variant="h6" component="h2" gutterBottom>
    Mission Statement
  </Typography>
  <Typography variant="body1" paragraph>
  The RealChange mission is clear: We're helping people everywhere make real change with their spare change. RealChange ensures that every penny of your rounded-up change directly benefits impactful charitable projects.Our approach not only makes giving effortless, but also maximizes the impact of each donation, ensuring that your contributions make a real difference in the world. Creating a seamless giving experience that maximizes the impact of each donation.
  </Typography>
  <Typography variant="body1" paragraph>
  At RealChange, we are more than just another nonprofit organization; we are a movement towards a more charitable and compassionate society. Join us in making a real change, one transaction at a time.
  </Typography>
  <Typography variant="h6" component="h2" gutterBottom>
  My Story
  </Typography>
  <Typography variant="body1" paragraph>
  The founding of RealChange stemmed from a growing frustration and a moment of clarity. I noticed a glaring gap in how we approach charity giving. The spark came when I observed the popularity of micro-investment platforms, which made investing accessible but also somewhat trivialized due to the limited impact of individual contributions. It dawned on me that if we could harness the simplicity and collective power of micro-investing for charitable purposes, we could create a monumental impact. That's when the idea of RealChange was conceived.
The motivation behind RealChange is to create a platform where every penny counts, transparency is paramount, and anyone can be a responsible investor in societal change.
Together, we're not just dropping coins into a jar; we're planting seeds that will grow into mighty oaks of charity. This is the heart of RealChange and why we are passionate about what we do.
  </Typography>
  <Typography variant="body1" paragraph>
  Additionally, as taught by the first Chabad Rebbe, there is a special virtue and benefit for the giver in not just the amount of charity given but in the multiplicity of times given, even if every time is only a small amount.
  </Typography>
  <Typography variant="h6" component="h2" gutterBottom>
  Our Values
  </Typography>
  <Typography variant="body1" paragraph>
  At the heart of RealChange lies a deep commitment to empathy and compassion, guiding us to understand and address the needs of those we serve. Integrity and trustworthiness form the foundation of our operations, ensuring honesty and ethical standards in all activities. Transparency and accountability are key to our approach, fostering trust and engagement with our donors and partners.
  </Typography>
  <Typography variant="h6" component="h2" gutterBottom>
  About Us
  </Typography>
  <Typography variant="body1" paragraph>
  We are proud to introduce our founders, Chunon Bartfield and Leivi Bartfield. With a shared vision for making a significant impact in our community, Chunon and Leivi bring extensive experience, dedication, and innovative thinking to our mission. Their leadership is grounded in a deep understanding of the challenges we aim to address and a commitment to developing sustainable solutions. Together, they embody the spirit of collaboration and compassion at the heart of our work. With their guidance, we are confident our nonprofit will achieve meaningful and lasting change.
  </Typography>
  <Typography variant="body1" paragraph>
  We are excited to partner with Collective Kindness, an amazing chesed fund that assesses families' situations, provides immediate assistance, and works tirelessly to create long-term solutions. We are also in the process of partnering with other charities and nonprofits.
  </Typography>
</Box>
    </Container>
  );
};

export default AboutUs;
