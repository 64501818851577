function formatDate(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString);
  
    if (isNaN(date.getTime())) {
        return "";
      }
    
    // Define arrays for month names and ordinal suffixes
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
      
    const day = date.getUTCDate();
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();
  
    // Determine the appropriate ordinal suffix for the day
    let daySuffix;
    if (day % 10 === 1 && day !== 11) {
      daySuffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
      daySuffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }
  
    // Construct and return the formatted date string
    return `${monthNames[month]} ${day}${daySuffix}, ${year}`;
  }
  

module.exports = {
    formatDate
};