import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { User } from '../AddressForm';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await axios.get(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/me' : 'https://realchange.club:8080/api/me', { withCredentials: true });
        setUser(res.data);
      } catch (err) {
        setUser(null);
      }
    };
    checkAuth();
  }, []);

  const donate = async (charity, frequency, customAmount, email, password) => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ?  'https://localhost:8080/api/donate' : 'https://realchange.club:8080/api/donate', { charity, frequency, customAmount, email, password }, { withCredentials: true });
    setUser(res.data.user);
    console.log(res.data);
  };

  const register = async (
    email,
    password,
    update = false) => {
      var res;
      if(!update) {
        res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/register' : 'https://realchange.club:8080/api/register', { email, password }, { withCredentials: true });
      } else {
        res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/update' : 'https://realchange.club:8080/api/update', { email, password }, { withCredentials: true });
      }

    setUser(res.data.user);
  };

  const login = async (email, password) => {
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/login' : 'https://realchange.club:8080/api/login', { email, password }, { withCredentials: true });
    setUser(res.data);
  };

  const logout = async () => {
    await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/logout' : 'https://realchange.club:8080/api/logout', { withCredentials: true });
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, register, login, logout, donate }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
