import axios from 'axios';
import React from 'react';

import {
  PlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
} from 'react-plaid-link';
import { Navigate } from 'react-router-dom';

interface Props {}
interface State {
  token: null;
  complete: boolean;
}
class PlaidLinkClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { token: null, complete: false };
  }
  async createLinkToken() {
    // get a link_token from your server
    var response = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/create_link_token' : 'https://realchange.club:8080/api/create_link_token', { }, { withCredentials: true });
    return response.data.link_token;
  }

  async componentDidMount() {
    const token = await this.createLinkToken();
    this.setState({ token });
  }

  onSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);
    var response = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/public_token' : 'https://realchange.club:8080/api/public_token', { metadata }, { withCredentials: true });
    if(response.data.status == 'success') {
        this.setState({complete: true});
    }
  };

  onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    console.log(eventName, metadata);
  };

  onExit: PlaidLinkOnExit = (error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    console.log(error, metadata);
  };

  render() {
    if(this.state.complete) return <Navigate to="/dashboard" replace={true} />
    return (
      <PlaidLink
        className="CustomButton"
        style={{ padding: '20px', fontSize: '16px', cursor: 'pointer' }}
        token={this.state.token}
        onSuccess={this.onSuccess}
        onEvent={this.onEvent}
        onExit={this.onExit}
      >
        Link your bank account
      </PlaidLink>
    );
  }
}

export default PlaidLinkClass;
