import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { EventEmitter } from 'stream';

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

interface AddressProps { 
  ref: React.Ref< { getFormData: () => User; } >;
}

export interface User {
  email: string;
  password: string;
}

export interface CreditCard {
  number: string;
  cvv: string;
  name: string;
  exp: string;
}


const AddressForm =  React.forwardRef((props: AddressProps, ref) => {
  const [user, setFormData] = React.useState<User>({
    email: '',
    password: ''
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...user, [name]: value });
  };

  React.useImperativeHandle(ref, () => ({
    getFormData: () => user,
  }));

  return (
    <Grid container spacing={3}>
      <FormGrid item xs={12}>
        <FormLabel htmlFor="email" required>
          Email
        </FormLabel>
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder="Email address"
          required
          value={user.email}
          onChange={handleInputChange}
        />
      </FormGrid>
      <FormGrid item xs={12}>
        <FormLabel htmlFor="password" required>Password</FormLabel>
        <OutlinedInput
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          value={user.password}
          onChange={handleInputChange}
          required
        />
      </FormGrid>
    </Grid>
  );

});

export default AddressForm;
