import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FaGift, FaGifts, FaPerson, FaShare } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton href='/'>
      <ListItemIcon>
      <FaHome size="1.6rem" />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>

    <ListItemButton href='dashboard'>
      <ListItemIcon>
      <FaGift size="1.6rem" />
      </ListItemIcon>
      <ListItemText primary="My Giving" />
    </ListItemButton>
    <ListItemButton href='all-giving'>
      <ListItemIcon>
      <FaGifts size="1.6rem" />
      </ListItemIcon>
      <ListItemText primary="Everyone's Giving" />
    </ListItemButton>
    <ListItemButton href='share'>
      <ListItemIcon>
        <FaShare
        size="1.6rem" />
      </ListItemIcon>
      <ListItemText primary="Invite Friends" />
    </ListItemButton>
    <ListItemButton href='account'>
      <ListItemIcon>
        <FaPerson size="1.6rem" />
      </ListItemIcon>
      <ListItemText primary="Account Settings" />
    </ListItemButton>
  </React.Fragment>
);
