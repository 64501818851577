import AppAppBar from './components/AppAppBar';
import axios from 'axios';

import * as React from 'react';
import { Container, Grid, Paper, Toolbar, MenuItem, Select, FormControl, InputLabel, TextField, Button, CssBaseline, ThemeProvider, createTheme, CircularProgress, LinearProgress } from '@mui/material';
import { AuthContext } from './context/AuthContext';
import PaymentForm from './PaymentForm';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { User } from './AddressForm';
import { useSearchParams } from 'react-router-dom';

const defaultTheme = createTheme();

const Donation = () => {
  const [charity, setCharity] = React.useState('Charity1');
  const [frequency, setFrequency] = React.useState('daily');
  const [customAmount, setCustomAmount] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { user, donate } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false); 
  const [step, setStep] = React.useState(1);

  const handleFrequencyChange = (e: any) => {
    setFrequency(e.target.value);
  };

  const handleCustomAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomAmount(event.target.value);
  };

  const handleBtnAmountChange = (event: any) => {
    setCustomAmount(event.target.value);
  };

  
  const stripe = useStripe();
  const elements = useElements();
  const addressFormRef = React.useRef<{ getFormData: () => User; }>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if(searchParams.get("setup_intent")) {
        const res = axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/donated' : 'https://realchange.club:8080/api/donated', { }, { withCredentials: true }).then(x => setStep(3));
    }
  }, []);

  const handleSavePaymentInfo = async () => {
    if(elements !== null && stripe !== null) {
        const {error: submitError} = await elements.submit();
        if(submitError) return;
  
        const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/intent' : 'https://realchange.club:8080/api/intent', { }, { withCredentials: true });
        const clientSecret = res.data.clientSecret;
        
        const {error} = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: 'https://realchange.club/donation',
          },
        });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    if(step == 1) {
        setLoading(true);
        await donate(charity, frequency, customAmount, email, password);
        setLoading(false);
        
        if(!(user?.stripePaymentId ?? null) || (user?.stripePaymentId ?? "") == '') {
            setStep(2);
        } else {
            setStep(3);
        }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>

    <CssBaseline />
<AppAppBar mode="light" />

<Container style={{'marginTop': '100px'}} maxWidth="sm">
   <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        {step == 1 && !loading && <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
          {user == undefined && <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid> }

            {user == undefined && <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="charity-label">Charity</InputLabel>
                <Select
                  labelId="charity-label"
                  id="charity-select"
                  value={charity}
                  label="Charity"
                >
                  <MenuItem value="Charity1">Collective Kindness</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="frequency-label">Frequency</InputLabel>
                <Select
                  labelId="frequency-label"
                  id="frequency-select"
                  value={frequency}
                  label="Frequency"
                  onChange={(e) => handleFrequencyChange(e)}
                >
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item container spacing={0}>
          {[1, 5, 18, 100].map((amount) => (
            <Grid item xs={3} key={amount}>
              <Button
                variant="contained"
                value={amount}
                onClick={(e) => handleBtnAmountChange(e)}
              >
                ${amount}
              </Button>
            </Grid>
          ))}
        </Grid>


            <Grid item xs={12}>
              <TextField
                fullWidth
                id="custom-amount"
                label="Amount"
                type="number"
                value={customAmount}
                required
                InputProps={{ inputProps: { min: 5 } }}
                onChange={handleCustomAmountChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">Submit</Button>
            </Grid>
          </Grid>
        </form>}

        {loading && <LinearProgress color="inherit" />}

        {step == 2 && <Grid>
            <Grid item><PaymentForm ref={addressFormRef} paymentType={'creditCard'}></PaymentForm> </Grid>
            <Grid item><Button style={{'marginTop': '15px'}} type="submit" variant="contained" color="primary" onClick={() => handleSavePaymentInfo()}>Donate</Button></Grid>
            </Grid>}

            {step == 3 && <Grid>
            <h3 style={{'textAlign': 'center'}}>Thank you!</h3>
            <p style={{'textAlign': 'center'}}>Your donation will be processed accordingly.</p>
            <p style={{'textAlign': 'center'}}>You can log into your personalized dashboard to track your giving over time.</p>
            </Grid>}
      </Paper>
    </Container>

    </ThemeProvider>
  );
};

export default Donation;
