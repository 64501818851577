// src/pages/ContactUs.js
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CssBaseline,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import AppAppBar from './components/AppAppBar';
import AboutUs from './AboutUs';

const About = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted', formData);
  };
  const defaultTheme = createTheme({ palette: { mode: 'light' } });

  return (
    <ThemeProvider theme={defaultTheme}>

              <CssBaseline />
      <AppAppBar mode="light" />

    <Container style={{'marginTop': '100px'}} maxWidth="sm">
      <AboutUs></AboutUs>
    </Container>
    </ThemeProvider>
  );
};

export default About;
