import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import LandingPage from './LandingPage';
import Dashboard from './Dashboard';
import Checkout from './Checkout';
import { AuthContext, AuthProvider } from './context/AuthContext';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import Login from './Login';
import EveryoneDashboard from './EveryoneDashboard';
import Account from './Account';
import AccountDashboard from './AccountDashboard';
import ContactUs from './ContactUs';
import ShareWithFriends from './ShareWithFriends';
import AboutUs from './AboutUs';
import Share from './Share';
import About from './About';
import Donation from './Donation';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage></LandingPage>,
  },

  {
    path: '/dashboard',
    element: <Dashboard></Dashboard>
  },

  {
    path: '/all-giving',
    element: <EveryoneDashboard></EveryoneDashboard>
  },

  {
    path: 'account',
    element: <AccountDashboard></AccountDashboard>
  },

  {
    path: '/signup',
    element: <Checkout></Checkout>
  },
  {
    path: '/login',
    element: <Login></Login>
  },
  {
    path: '/contact',
    element: <ContactUs></ContactUs>
  },
  {
    path: '/share',
    element: <Share></Share>
  },
  {
    path: '/about',
    element: <About></About>
  },
  {
    path: '/donation',
    element: <Donation></Donation>
  }
]);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Pj6OZ2MoTNIHgyPn4LGXoQfKKroPi7p4LtMwmZeV2YPmLt20S5furgdazbinLFcR9gGKO03SSOGsAWvkqaQ9x6L00Y74Uihbn');

root.render(
  <React.StrictMode>
     <Elements stripe={stripePromise} options={{'mode': 'setup', 'currency': 'usd'}}>
    <AuthProvider>
       <RouterProvider router={router}></RouterProvider>
       </AuthProvider>
       </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
