import React from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';

const ShareWithFriends = () => {
  const appLink = 'https://your-application-link.com';

  const handleCopyLink = () => {
    navigator.clipboard.writeText(appLink)
      .then(() => {
        alert('Link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" component="h1" gutterBottom>
          Share with Friends
        </Typography>
        <TextField
          label="Application Link"
          value={appLink}
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" fullWidth onClick={handleCopyLink}>
            Copy Link
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ShareWithFriends;
