import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import { AuthContext } from './context/AuthContext';
import { Navigate } from 'react-router-dom';
import Title from './Title';
import axios from 'axios';
import { formatDate } from './helpers';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Payment } from './Payment';
import { BarChart, ChartsTextStyle, LineChart, PieChart, ScatterChart, axisClasses } from '@mui/x-charts';
import Account from './Account';
import ShareWithFriends from './ShareWithFriends';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Share() {
  const { user } = React.useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  if(user == undefined) return <a href='/'>Cannot sign in</a>;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <ShareWithFriends></ShareWithFriends>
              {/* Chart */}
              {/* <Grid item xs={12} md={8} lg={9}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <React.Fragment>
      <Title>Overview</Title>
      <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
      <PieChart
  series={[
    {
      data: [
        { id: 0, value: donationsByMonth[0], label: 'January' },
        { id: 1, value: donationsByMonth[1], label: 'February' },
        { id: 2, value: donationsByMonth[2], label: 'March' },
        { id: 3, value: donationsByMonth[3], label: 'April' },
        { id: 4, value: donationsByMonth[4], label: 'May' },
        { id: 5, value: donationsByMonth[5], label: 'June' },
        { id: 6, value: donationsByMonth[6], label: 'July' },
        { id: 7, value: donationsByMonth[7], label: 'August' },
        { id: 8, value: donationsByMonth[8], label: 'September' },
        { id: 9, value: donationsByMonth[9], label: 'October' },
        { id: 10, value: donationsByMonth[10], label: 'November' },
        { id: 11, value: donationsByMonth[11], label: 'December' }
      ],
    },
  ]}
/>
      </div>
    </React.Fragment>
                </Paper>
              </Grid> */}
              {/* Recent Deposits */}
              {/* <Grid item xs={12} md={4} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                      <React.Fragment>
      <Title>Total Donations</Title>
      <Typography component="p" variant="h4">
        { '$' + totalUserDonations }
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {earliestDonationDate == "" ? "" : "since" }  { earliestDonationDate }
      </Typography>
    </React.Fragment>
                </Paper>
              </Grid> */}
              {/* Recent Orders */}
              {/* {isAdmin && <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <React.Fragment>
      <Title>Recent Donations</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {donations.map((row) => (
            <TableRow>
              <TableCell>{formatDate(row.created)}</TableCell>
              <TableCell>{"$" + String(row.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    </React.Fragment>
                </Paper>
              </Grid> } */}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
