// src/pages/ContactUs.js
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CssBaseline,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import AppAppBar from './components/AppAppBar';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted', formData);
    const res = await axios.post(process.env.NODE_ENV == 'development' ? 'https://localhost:8080/api/message' : 'https://realchange.club:8080/api/message', formData, { withCredentials: true });
    window.location.reload();
  };
  const defaultTheme = createTheme({ palette: { mode: 'light' } });

  return (
    <ThemeProvider theme={defaultTheme}>

              <CssBaseline />
      <AppAppBar mode="light" />

    <Container style={{'marginTop': '100px'}} maxWidth="sm">
      <Box >
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
          />
          <Box mt={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
    </ThemeProvider>
  );
};

export default ContactUs;
